<template>
    <div class="content">
        <div v-show='showWechat'>
			<div class="wechat">
				<p class="red"><strong>请点击右上角 [ ··· ] </strong></p>
				<p>选择 <strong class="red">在浏览器打开</strong></p>
				<p>否则可能会无法打开下载链接</p>
			</div>
		</div>
        <a-tabs v-model:activeKey="activeKey" centered>
            <a-tab-pane key="1" tab="精读圣经">
                <BibleView />
            </a-tab-pane>
            <a-tab-pane key="2" tab="诗歌本" force-render>
                <div class="tab-pane fade" id="tab2">
                    <HymnView />                  
                </div>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
import { ref } from 'vue';
import BibleView from './BibleView.vue'
import HymnView from './HymnView.vue'
export default {
    name: 'HomeView',
    props: {

    },
    data() {
        return {
            showWechat: false,
            activeKey: ref('1'),
        };
    },
    computed: {

    },
    setup() {
        
    },
    created() {
        if (this.global.is_weixin()) {
            this.showWechat = true
        }
        if (this.$route.query.app == 'hymn') {
            this.activeKey = ref('2')
        }
    },
    mounted() {

    },
    watch: {

    },
    methods: {
        openHref (category, url) {
            window.location.href = url
            this.$gtag.event('button', { 'event_category': category, 'event_label': url })
            this.$ba.trackEvent(category, 'button', url, '')
        },
    },
    components: {
        BibleView,
        HymnView
    },
};
</script>

<style scoped>

.content {
    display:inline-block;
    text-align: left;
    max-width: 800px;
}
.wechat {
    position:relative;
    padding-top:20px;
    padding-bottom: 10px;
    color: black;
    background-color: #ebe7e7;
    font-size: 22px;
    text-align: center;
}
.red {
    color: red;
}
.description {
    color: gray;
    padding: 10px;
    -webkit-overflow-scrolling:touch;
    background-color:#efeff4;
}
.info {
    color: gray;
}
.os {
    padding: 10px;
}
p {
    margin: 0px;
}
a-divider {
    height: 1px;
}
.divider {
    width: 100%;
    height: 1px;
    position: relative;
    background-color: #eeeeee;
}
</style>
