import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: "精读圣经诗歌本官网"
    } 
  },
  {
    path: '/all',
    name: 'all',
    component: HomeView,
    meta: {
      title: "精读圣经诗歌本官网"
    } 
  },
  {
    path: '/bible',
    name: 'bible',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BibleHomeView.vue'),
    meta: {
      title: "精读圣经官网"
    } 
  },
  {
    path: '/hymn',
    name: 'hymn',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HymnHomeView.vue'),
    meta: {
      title: "诗歌本官网"
    } 
  },
  {
    path: '/donate',
    name: 'donate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DonateView.vue')
  },
  {
    path: '/zan',
    name: 'zan',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ZanView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
