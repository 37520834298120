 var global = {
    loadFile (name){
        const xhr = new XMLHttpRequest()
        const okStatus = document.location.protocol === 'file:' ? 0 : 200
        xhr.open('GET', name, false)
        xhr.overrideMimeType('text/html;charset=utf-8')// 默认为utf-8
        xhr.send(null)
        return xhr.status === okStatus ? xhr.responseText : null
    },
    /**
     * 判断浏览器类型：Android/iOS
     */
    getOSType() {
        if (/(Android)/i.test(navigator.userAgent)) {
            return 'Android'
        } else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
            return 'iOS'
        }
    },
    is_weixin() {
        if (/(micromessenger)/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
    },
    is_qq() {
        if (/(MQQBrowser)/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
    }
 }
 export default global;

 