import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

//import ElementPlus from 'element-plus'
//import 'element-plus/dist/index.css'

import Ant from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
//import * as Icons from '@ant-design/icons-vue'

//import axios from 'axios'
//import VueAxios from 'vue-axios'

import "./assets/iconfont/iconfont.css"

import global from './base'   

import VueGtag from 'vue-gtag'
import ba from 'vue-ba'

const app = createApp(App)
app.use(router)
//app.use(ElementPlus)
app.use(Ant)
//谷歌统计
//znapps.icu的id为：G-V6NPE7GY0G
//znapps.net的id为：G-7PD5R21LWL
try {
  app.use(VueGtag, {
      config: { id: "G-V6NPE7GY0G" } 
    }, router);
} catch {
  console.log('谷歌统计异常')
}
//百度统计 znapps.net
try {
  app.use(ba, { siteId: 'c51ccd9da3f6c4295f82ccb5664e751e' })
} catch {
  console.log('百度统计异常')
}
//app.use(VueAxios, axios)
// 注册图标组件
// for (const i in Icons) {
//     app.component(i, Icons[i])
// }
app.config.globalProperties.global = global;
app.mount('#app')
